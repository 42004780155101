<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>

        <template slot="header">
          <span class="mt-2">Commission : {{ details.value }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <div id="error" class="card border-danger" v-show="error">
          <div class="card-header bg-danger border-danger">

              <span class="text-white font-weight-bolder">
              <i class="fa fa-exclamation-triangle"></i>
               &nbsp;
              {{ error_heading }}
              </span>

          </div>
          <div class="card-body border-danger text-dark font-weight-bold">

            {{ error_text }}

          </div>
        </div>

        <div>

          <b-form-row class="mb-3">

            <b-col cols="6">
              <label class="">Value</label>
              <b-input v-model="$v.details.value.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('value')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.value.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="6">
              <label class="">CSID</label>
              <b-input v-model="$v.details.csid.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('csid')"></b-input>
              <b-form-invalid-feedback v-if="!$v.details.csid.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

          </b-form-row>

        </div>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button variant="success" @click="editAddon" class="float-right">Edit Commission</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import { validationMixin } from 'vuelidate'
  import { required, requiredIf, decimal } from "vuelidate/lib/validators";

  export default {
    name: 'CommissionEdit',
    components: {
      Id,
    },
    mixin: [validationMixin],
    props: {
      caption: {
        type: String,
        default: 'Edit'
      },
    },
    data: () => {
      return {
        details: {
          value: "",
          csid: "",
        },
        error: false,
        error_heading: '',
        error_text: '',
        server_errors: {
          value: null,
          csid: null,
        }
      }
    },
    validations() {

      return {
        details: {
          value: {
            decimal,
            required
          },
          csid: {
          },
        }
      }

    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      validateState(name) {

        if (this.$v.details[name]) {
          const {$dirty, $error} = this.$v.details[name];
          return $dirty ? !$error : null;
        }
      },
      resetServerError(name) {

        if (this.server_errors[name]) {
          this.server_errors[name] = null;
        }

      },
      getAddon() {
        let url = '';

        url = '/commission/' + this.$route.params.id + '/?product=' + this.$route.params.product

        axios.get(url).then(
          response => {
            this.details = response.data;
          }
        ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('premium error');
            console.log(error.response)
          })
      },
      editAddon() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {

          window.scrollTo(document.querySelector('#error').offsetTop, 100);
          this.error = true;
          this.error_heading = 'There are errors on the form';
          this.error_text = 'Please fix the errors and re-submit the form';
          return

        } else {

          const post_data = JSON.parse(JSON.stringify(this.details));

          let url = '';

          url = '/commission/' + this.$route.params.id + '/?product=' + this.$route.params.product;

          axios.put(url, post_data).then(
            response => (
              this.data = response.data
            )
          ).then(response => {
            response

            let alert = {
              type: 'success',
              text: 'Commission Value : ' + this.details.value + ' was updated successfully!'
            };

            this.$store.commit(
              'setGlobalAlert',
              alert
            )
            this.$router.replace('/system-config/products/' + this.$route.params.product + '/commission/')

        }).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            if (error.response.status === 400) {

              window.scrollTo(document.querySelector('#error').offsetTop, 100);
              this.error = true;
              this.error_heading = 'There are errors on the form';
              this.error_text = 'Please fix the errors and re-submit the form';

              if (error.response.data) {

                //this.server_errors = error.response.data

                for (var key in error.response.data){

                  this.server_errors[key] = error.response.data[key][0]
                }
                this.$v.$reset();
                this.$v.$touch();

              }

            }

            console.log('premium error');
            console.log(error.response)
          });

        }
      }
    },
    mounted() {
      this.getAddon()
      this.$v.details.$reset();
      this.$v.$reset()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
